




import React from "react";
import "../App.css";

import { IoMdMenu } from "react-icons/io";
import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Dropdown from 'react-bootstrap/Dropdown';

import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import bglogo from "../images/bglogo.png";
import bglogoo from "../images/bglogoo.jpg";
import Accordion from 'react-bootstrap/Accordion';
import { FaXTwitter } from "react-icons/fa6";
// import search from "../images/search_white.png"
import { FaLinkedin, FaTwitter, FaFacebook, FaSearch,FaWhatsapp } from "react-icons/fa";

window.onscroll = function() {
  var navbar = document.querySelector('.naviBox');
  if (window.pageYOffset > 0) { // 2rem converted to pixels
    navbar.classList.add('with-margin', 'fixed');
  } else {
    navbar.classList.remove('with-margin', 'fixed');
  }}
const Header = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 
 

  return (
    <div >
      <header className="headercol ">
        <div className="centerColbb">
          <div className="upper_header">

           

            <div className="imgbuto">
              <img src={bglogo} alt="fghj" width={300} />
            </div>

            <ul className="row_flex">

             
<li className=" "> <Link to="https://www.facebook.com/profile.php?id=61554756211576">  <FaFacebook className="mnop"/> </Link>

</li>
<li className="">
  <Link to= "https://www.linkedin.com/company/kataria-and-associates/">
   <FaLinkedin className="mnop"/></Link></li>
   <li className="" ><Link to = "https://wa.me/+919650756107?text=Hi+Advocates+I+am+interested+in+your+services+for+my+case+with+your+company+Kataria+and+Associates+"> <FaWhatsapp className="mnop"/></Link></li>
<li className=""> 
<Link to = "https://twitter.com/Katariassociate/likes">
<FaXTwitter className="mnop" /></Link></li>



</ul>

            <div>
              <Form className="d-flex ">
                <Form.Control
                  type="search"
                  placeholder="May I help you: Type your query"
                  className="me-2 kmssa"
                  aria-label="Search"
                >

                </Form.Control>
                <button className="sbtn1 kmssa">
                  <FaSearch />
                </button>
              </Form> </div>
          </div>
        </div>
        <div className="naviBox fixed-element">
          <div className="centerCol">
            <div className="menu_btn ">
              <div><> <IoMdMenu onClick={handleShow} className="home_btn_menu" /> &nbsp; &nbsp; &nbsp; <input type=" texy" className="inpsearch" placeholder="Search your query" ></input></>
              </div>
              <div>
              </div>
            </div>
            <ul className="navbar_h">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/AdvocatePage">Advocates</Link>
              </li>
              <li>
                <Link to="/Resource">Resources</Link>
              </li>
              <li>
                <Link to="/Blog">Blogs</Link>
              </li>

              <li>
                <Link to="/Service">Services</Link>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    Users
                  </Dropdown.Toggle>
                  <Dropdown.Menu >
                    <Dropdown.Item  ><Link to="/Student" > Student</Link></Dropdown.Item>
                    <Dropdown.Item ><Link to="/Faculty"> Faculty</Link></Dropdown.Item>
                    <Dropdown.Item  ><Link to="/Litigants"> Litigants</Link></Dropdown.Item>
                    <Dropdown.Item ><Link to="/Judiciary">Judiciary </Link></Dropdown.Item>
                    <Dropdown.Item ><Link to="/advocate-form">Advocate Page </Link></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                <Link to="/Media">Media </Link>
              </li>
              <li>
                <Link to="/contact"> Contact</Link>
              </li>
              <li>
                <Link to="/test_Login"> Online test</Link>
                {/* <Link to="/TestDemo"> Online test</Link> */}
              </li>
            </ul>
          </div>
        </div>
      </header>


      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="row"><img src={bglogoo} alt="fghj" width={300} />   <ul className="row_flex">

          <li className="m-1 "> <Link to="https://www.facebook.com/profile.php?id=61554756211576">  <FaFacebook className="mnopp"/> </Link>
              
              </li>
              <li className="m-1">
                <Link to= "https://www.linkedin.com/company/kataria-and-associates/">
                 <FaLinkedin className="mnopp"/></Link></li>
                 <li className="m-1" ><Link to = "https://wa.me/+919650756107?text=Hi+Advocates+I+am+interested+in+your+services+for+my+case+with+your+company+Kataria+and+Associates+"> <FaWhatsapp className="mnopp"/></Link></li>
              <li className="m-1"> 
              <Link to = "https://twitter.com/Katariassociate/likes">
              <FaXTwitter className="mnopp" /></Link></li>
          </ul>

            <hr /></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="side_bar">
            <li className="mnopp">
              <Link to="/" onClick={handleClose}>Home</Link>
            </li>
            <hr />
            <li className="mnopp">
              <Link to="/about" onClick={handleClose}>About Us</Link>
            </li>
            <hr />
            <li>
              <Link to="/AdvocatePage" onClick={handleClose}>Advocates</Link>
            </li>
            <hr />
            <li>
              <Link to="/Resource" onClick={handleClose}>Resources</Link>
            </li>
            <hr />
            <li>
              <Link to="/Blog" onClick={handleClose}>Blogs</Link>
            </li>
            <hr />
            <li>
              <Link to="/Service" onClick={handleClose}>Services</Link>
            </li>
            <hr/>

<li>  
<Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header>Users</Accordion.Header>
        <Accordion.Body>
        <li>
              <Link to="/advocate-form" onClick={handleClose} >Advocate Page </Link>
            </li>
            <hr />
            <li>
              <Link to="/Judiciary" onClick={handleClose} >Judiciary </Link>
            </li>
            <hr />

            <li>
              <Link to="/Student" onClick={handleClose}>Student</Link>
            </li>
            <hr />
            <li>
              <Link to="/Faculty" onClick={handleClose}> Faculty</Link>
            </li>
            <hr />
            <li>
              <Link to="/Litigants" onClick={handleClose}> Litigants</Link>
            </li>
           
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

</li>
<hr></hr>
<br></br>
            
            <li>
              <Link to="/Media" onClick={handleClose} >Media </Link>
            </li>
            <hr />
           
            <li>
              <Link to="/contact" onClick={handleClose}> contact</Link>
            </li>
            <hr />
            <li>
              <Link to="/Test_Login"  onClick={handleClose}> online Test</Link>
            </li>
            <hr/>

          </ul>

        </Offcanvas.Body>
      </Offcanvas>
    </ div>
  );
};

export default Header;
