import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import {FaLinkedin,FaTwitter,FaWhatsapp,FaFacebook } from "react-icons/fa";
import contimg from "../images/contact.png"
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Button } from "react-bootstrap";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {BACKEND_URL} from "../services/helper"
import { FaXTwitter } from "react-icons/fa6";

const Contact = () => { 

  const [formData, setFormData] = useState({
    fname:"",
      lname:"",
      mobile:"",
      email:"",
     message :""
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
console.log("formData",formData)
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataForApi = new FormData();
      for (const key in formData) {
        formDataForApi.append(key, formData[key]);
      }

      const response = await axios.post(`${BACKEND_URL}/form/submit`, formDataForApi);

      console.log(response.data); // Handle the response as needed
      toast.success("submitted successfully");
      // Optionally, you can redirect to another page or show a success message
    } catch (error) {
      console.error(error);
      // Handle errors, show an error message, etc.
    }
  };


  return (
    <div className="centerCol">
      <div className="welcomeText">
        <h3>Contact Us</h3>
        <p> Any question or remarks? Just write us a message!</p>
      </div>

      <div className="center1">

        <Card style={{ width: '900px' }} className="border_colo">
          <Card.Body className="card_flex1">

            <div className="map1">
              <h3> Get in <span style={{ color: 'green' }}> touch</span></h3>
              <h6 className="ctexta"> You can find our office's physical location on the map provided below, making it easy to visit us in person.</h6>
              <p> contact information</p>
             
              <p> Mob. : + 91 96507 56107 </p>
              <p> Email : cssanjeevkataria@gmail.com </p>
              <p className="ctexta">Post: Kataria and Associates, 443, 443A, Civil Wing, Tis Hazari Court, Delhi 110 054 </p>
              <ul className="icons"> 
           <ul className="row_flex">

             
<li className="m-1 "> <Link to="https://www.facebook.com/profile.php?id=61554756211576">  <FaFacebook className="mno"/> </Link>

</li>
<li className="m-1">
  <Link to= "https://www.linkedin.com/company/kataria-and-associates/">
   <FaLinkedin className="mno"/></Link></li>
   <li className="m-1" ><Link to = "https://wa.me/+919650756107?text=Hi+Advocate+Anju+I+am+interested+in+your+services+for+my+case+with+your+company+Kataria+and+Associates+"> <FaWhatsapp className="mno"/></Link></li>
<li className="m-1"> 
<Link to = "https://twitter.com/Katariassociate/likes">
<FaXTwitter className="mno" /></Link></li>



</ul>
           </ul>
            </div>


            <div className="map">
              <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" 
              
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d218.79804823037466!2d77.2211074719675!3d28.666650054639955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd0039fb19dd%3A0xce4c0785c915cbf3!2sKataria%20%26%20Associates!5e0!3m2!1sen!2sin!4v1706166496562!5m2!1sen!2sin" referrerpolicy="no-referrer-when-downgrade">
              
              {/* src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=28%C2%B040'00.0%22N%2077%C2%B013'16.5%22E+(My%20BKATARIA%20AND%20ASSOCIATESusiness%20Name)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"> */}
                
                <a href="https://www.maps.ie/population/">Calculate population in area</a></iframe></div>

          </Card.Body>
        </Card>

      </div>
      <div className="center1 m-3">
        <Card style={{ width: '900px' }} className="bgcontac">
          <Card.Body className="card_flex1" >
            <div className="cibox">
              <img src={contimg} alt="contimg" />
            </div>
            <div className="cibox">

              <div className="displayflex m-2">
                <InputGroup>
                  <Form.Control
                    placeholder="FirstName"
                    aria-label="FirstName"
                    aria-describedby="basic-addon1"
                    className="m-1"
                    name='fname'
                    onChange={handleInputChange}
                  />
                </InputGroup>
                <InputGroup>
                  <Form.Control
                    placeholder="LastName"
                    aria-label="LastName"
                    aria-describedby="basic-addon1"
                    className="m-1"
                    name='lname'
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </div>

              <div className="displayflex m-2">
                <InputGroup>
                  <Form.Control
                    placeholder="Email"
                    aria-label="Email"
                    aria-describedby="basic-addon1"
                    className="m-1"
                    name='email'
                    onChange={handleInputChange}
                  />
                </InputGroup>
                <InputGroup>
                  <Form.Control
                    placeholder="Phone"
                    aria-label="Phone"
                    aria-describedby="basic-addon1"
                    className="m-1"
                    name='mobile'
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </div> <br/>
              <div >
              <Form.Label htmlFor="inputPassword5">Write you query here.</Form.Label>
            </div> 

            <div className="textareas m-2"> 
            <FloatingLabel controlId="floatingTextarea2" label="Write  your Message ..."   name='message' onChange={handleInputChange}>
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: '100px' }}
        />
      </FloatingLabel>
            </div> 
          <center> <Button className="m-3 flotLeft sub-btn1 " onClick={handleSubmit}> Submit</Button></center> 
            </div> 

           
          </Card.Body>
        </Card>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
